var map = null;

function initMap() {
  //创建地图
  map = new AMap.Map('container', {
    resizeEnable: true, //是否监控地图容器尺寸变化
    zoom: 20, //初始化地图层级
    center: [108.887471, 34.244552], //初始化地图中心点
  });
  // 添加起点标记
  var loadingIcon = new AMap.Icon({
    size: new AMap.Size(25, 45), // 图标尺寸
    image: '//webapi.amap.com/theme/v1.3/markers/b/mark_bs.png', // Icon的图像
    imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
    imageSize: new AMap.Size(18, 30), // 根据所设置的大小拉伸或压缩图片
  });
  var Marker = new AMap.Marker({
    icon: loadingIcon,
    position: [108.887471, 34.244552],
    offset: new AMap.Pixel(-13, -30),
    map: map,
  });
  infoWindow();
  AMap.event.addListener(Marker, 'click', function() {
    infoWindow();
  });
}

function infoWindow(loadingIcon) {
  // infowidnow 的 innerHTML
  var infoWindowContent =
    '<label style="color: #c50007">陕西能源化工交易所</label>' +
    '<div style="font-size:12px;margin-top:4px;">陕西省西安市雁塔区沣惠南路中华世纪城商住楼8号楼二层</div>';

  // 创建一个自定义内容的 infowindow 实例
  var infoWindow = new AMap.InfoWindow({
    anchor: 'bottom-left',
    position: [108.887471, 34.244552],
    offset: new AMap.Pixel(0, -35),
    content: infoWindowContent,
  });

  infoWindow.open(map);
}

function clearMap() {
  if (map) {
    map.destroy();
  }
}

export default {
  initMap: initMap,
  clearMap: clearMap,
};
