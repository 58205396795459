<template>
    <div class="content-container">
        <!-- 头部导航组件 -->
        <navbar-header></navbar-header>

        <!-- 轮播图 -->
        <div style="min-width: 1200px;">
            <el-carousel :interval="5000" arrow="always" :height="imgHeight">
                <el-carousel-item v-for="(item,index) in imgList" :key="index">
                    <img style="width: 100%;" :src="item" alt="" ref="imgHeight">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 公司简介 -->
        <div class="gsjj animate__animated animate__zoomInUp">
            <div class="bx box">
                <div class="title">公司简介</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <div class="flex_row" style="width: 1200px;">
                    <img src="../assets/img/jj_img.png" style="width: 580px;height: 480px;border-radius: 0 50px;margin-right: 40px;" alt="">
                    <div class="gsjj_con" style="width: 620px;">
                        <div>
                            陕西能源化工交易所是经陕西省政府批准、国务院部际联席会议备案的全国唯一 一家能源化工交易所。
                        </div>
                        <div>
                            于2007年11月在欧亚经济论坛上揭牌成立, 2008年10月，完成股权结构调整，成为延长石油（集团）有限责任公司控股子公司。
                        </div>
                        <div>
                            公司位于西安市高新技术产业开发区，是以互联网、物联网、云计算、大数据等为核心技术，
                            为能源化工等大宗商品提供信息服务、交易服务和仓储物流服务的高新技术企业。
                        </div>
                        <div>
                            目前，交易产品涉及油品、化工、燃气、煤炭、钢铁、水泥、有色金属等大宗商品217种。
                        </div>
                        <div>
                            拥有大宗物资交易会员<span style="color: #FF4238 ;">4117</span>家，分布在全国30个省市自治区。
                        </div>
                        <div>
                            截止2022年底，平台累计成交额超<span style="color: #FF4238 ;">2264</span>亿元。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 企业文化 -->
        <div class="qywh">
            <div class="bx box">
                <div class="title">公司简介</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <img src="../assets/img/qywh_img.png" style="width: 1200px;" alt="">
            </div>
        </div>

        <!-- 发展历程 -->
        <div class="fzlc">
            <div class="bx box" style="position: relative;">
                <div class="title">发展历程</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <div class="line"></div>
                <div class="fzlc_con" id="out" ref="out">
                    <div class="con" v-for="(item,index) in list" :key="index">
                        <div class="year">
                            <div style="margin-left: 8px;">
                                {{ item.name }}年
                            </div>
                            <div class="ball2"></div>
                        </div>
                        <div class="month_con">
                            <div class="flex_con" v-for="(item2,key) in item.array" :key="key">
                                <div style="width: 34px;">{{ item2.month }}</div>
                                <div class="ball"></div>
                                <div style="width: calc(100% - 70px);">{{ item2.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <i class="el-icon-arrow-left arrow" @click="toleft()"></i>
                    <i class="el-icon-arrow-right arrow" style="margin-left: 4px;" @click="toright()"></i>
                </div>
            </div>
        </div>

        <!-- 企业荣誉 -->
        <div class="qyry">
            <div class="bx box">
                <div class="title">企业荣誉</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <el-carousel :interval="5000" arrow="always" height="500px" style="width: 1200px;">
                    <el-carousel-item>
                        <div style="position: relative;">
                            <img src="../assets/img/qyryfzt.png" style="width: 100%;margin-top: 130px;" alt="">
                            <div class="xiangkuang_row">
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/xjjc.jpg" alt=""></div>
                                    <div class="bt">先进基层党组织</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/bgqy.png" alt=""></div>
                                    <div>陕西省产业互联网标杆企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/16dsqy.png" alt=""></div>
                                    <div class="bt">2016年陕西十大电商企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/fhzdw.png" alt=""></div>
                                    <div class="bt">副会长单位</div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative;">
                            <img src="../assets/img/qyryfzt.png" style="width: 100%;margin-top: 130px;" alt="">
                            <div class="xiangkuang_row">
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/syqy.png" alt=""></div>
                                    <div class="bt">陕西省电子商务示范企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/aaaj.png" alt=""></div>
                                    <div class="bt">企业信用AAA级信用单位</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/xasdzsw.png" alt=""></div>
                                    <div class="bt">西安市电子商务示范企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/zglm.png" alt=""></div>
                                    <div class="bt">高技能人才职工(劳模)创新工作室</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div style="position: relative;">
                            <img src="../assets/img/qyryfzt.png" style="width: 100%;margin-top: 130px;" alt="">
                            <div class="xiangkuang_row">
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/xsjc.png" alt=""></div>
                                    <div class="bt">国家工业电子商务运行形势检测指数企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/aaaj.png" alt=""></div>
                                    <div class="bt">国家优秀工业电子商务平台案例企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/yxgy.png" alt=""></div>
                                    <div class="bt">国家优秀工业电子商务平台案例企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/xasfhz.png" alt=""></div>
                                    <div class="bt">西安市电子商务协会副会长</div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative;">
                            <img src="../assets/img/qyryfzt.png" style="width: 100%;margin-top: 130px;" alt="">
                            <div class="xiangkuang_row">
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/ajnsr.png" alt=""></div>
                                    <div class="bt">A级纳税人</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/hgqygl.png" alt=""></div>
                                    <div class="bt">第十二届全国石油和化工企业管理创新成果一等奖</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/gxjsqy.png" alt=""></div>
                                    <div class="bt">高新技术企业</div>
                                </div>
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/cxsc.png" alt=""></div>
                                    <div class="bt">中国大宗商品现代流通诚信市场</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div style="position: relative;">
                            <img src="../assets/img/qyryfzt.png" style="width: 100%;margin-top: 130px;" alt="">
                            <div class="xiangkuang_row">
                                <div class="xiangkuang_one">
                                    <div class="xiangkuang"><img src="../assets/img/zizhi/xjdw.png" alt=""></div>
                                    <div class="bt">数字化创新发展先进单位</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <!-- 联系我们 -->
        <div class="lxwm">
            <div class="bx box">
                <div class="title">联系我们</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <div class="flex_row">
                    <div id="container"></div>
                    <div class="dz">
                        <div>地 址：陕西省西安市雁塔区沣惠南路中华世纪城（东门）商住楼8号楼二层</div>
                        <div>邮 编：710065</div>
                        <div>财务资金部：029-81777953</div>
                        <div>客户服务部：029-81777905</div>
                        <div>采购交易部：029-89199820</div>
                        <div>化工品交易部：029-89589971</div>
                        <div>油煤气交易部：029-89288390</div>
                        <div>传 真：029-89286898</div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 底部   -->
        <nav-footer></nav-footer>
    </div>
</template>
<script>
import NavbarHeader from "../components/home/newHeader.vue"
import NavFooter from "../components/common/NavFooter.vue"
import map from '../map'

export default {
    components: {
        NavbarHeader,
        // NewsList,
        NavFooter,
    },
    data () {
        return {
            imgHeight: '0px',
            imgList: [],
            step: 980,
            scrollLeft: 0,
            list: [
                {
                    name: 2022,
                    array: [
                        { month: '01月', text: '再获国家级“高新技术企业”资质认定。' },
                        { month: '07月', text: '交易所党支部获评延长石油集团“示范党支部”。' },
                        { month: '08月', text: '平台累计成交额突破2000亿元。' },
                        { month: '10月', text: '完成延长云商APP项目研发建设，实现“掌上交易”。' },
                        { month: '12月', text: '成功召开数智化营销项目启动会。' }
                    ]
                },
                {
                    name: 2021,
                    array: [
                        { month: '09月', text: '成功通过国家“科技型中小企业”资质认定' },
                        { month: '08月', text: '完成延长云商会员中台和资金中台系统研发建设工作。' },
                        { month: '07月', text: '交易所党支部被评为延长石油集团“先进基层党组织”。' },
                        { month: '01月', text: '全面启动“入会零门槛、挂单预通知、竞拍不锁时、会员零收费”一体化联动服务。' },
                    ]
                },
                {
                    name: 2020,
                    array: [
                        { month: '09月', text: '山西能化商品数字科技有限公司成立。' },
                        { month: '06月', text: '铜川子公司成立。' },
                        { month: '04月', text: '完成公司股份制改革。' },
                        { month: '04月', text: '竞价销售新系统实现全产品切换上线运行' },
                    ]
                },
                {
                    name: 2019,
                    array: [
                        { month: '12月', text: '平台历年累计成交额达1063亿元，突破千亿大关。' },
                        { month: '12月', text: '大数据平台正式上线运行，交易所进入大数据2.0时代。' },
                        { month: '10月', text: '荣获“第十二届全国石油和化工企业管理创新成果”一等奖。' },
                        { month: '01月', text: '与京东集团签署战略合作协议，成为战略合作伙伴。' },
                    ]
                },
                {
                    name: 2018,
                    array: [
                        { month: '12月', text: '完成公司混合所有制改革。' },
                        { month: '12月', text: '荣获国家信息安全发展研究中心颁发的“国家优秀工业电子商务平台案例企业”“国家工业电子商务运行形势检测指数企业”。' },
                        { month: '10月', text: '顺利通过”高新技术企业“认定。' },
                        { month: '05月', text: '获得11项中华人民共和国国家版权局《计算机软件著作登记证书》。' },
                        { month: '03月', text: '延长云商平台召开终验会，平台进入全面上线运营阶段。' },
                    ]
                },
                {
                    name: 2017,
                    array: [
                        { month: '11月', text: '延长云商项目第二阶段验收会召开，平台项目建设按计划全部完成。' },
                        { month: '10月', text: '全年成交额达104.9亿元，平台运行十年以来首次突破百亿。' },
                        { month: '09月', text: '被授予“陕西省产业互联网标杆企业”荣誉称号。' },
                        { month: '08月', text: '公司正式启动资本市场上市工作。' },
                        { month: '02月', text: '公司正式更名为陕西能源化工交易所有限公司。' },
                    ]
                },
                {
                    name: 2016,
                    array: [
                        { month: '12月', text: '延长云商硬件基础平台联调成功。' },
                        { month: '09月', text: '荣获“2016年陕西十大电商企业”称号。' },
                        { month: '07月', text: '延长云商开展模块需求调研、架构设计、开发测试等工作。' },
                        { month: '05月', text: '交易中心举行热力公司物资采购首次远程专场交易。' },
                    ]
                },
                {
                    name: 2014,
                    array: [
                        { month: '12月', text: '平台全年成交额突破90亿元。' },
                        { month: '07月', text: '交易所被授予“陕西省电子商务示范企业”荣誉称号。' },
                        { month: '03月', text: '延长石油2#渣油产品成功实现上市交易。' },
                    ]
                },
                {
                    name: 2013,
                    array: [
                        { month: '12月', text: '平台全年成交额突破40亿元。' },
                        { month: '07月', text: '陕西延长中立新能源股份有限公司签订战略合作协议， 实现了西部地区成品油首次现货电子交易。' },
                        { month: '06月', text: '进行了第一届工会委员会民主选举，顺利完成了工会组建。' },
                        { month: '01月', text: '神华集团有限责任公司聚乙烯、聚丙烯产品成功实现上市交易。' },
                    ]
                },
                {
                    name: 2012,
                    array: [
                        { month: '12月', text: '平台全年成交额突破20亿元。' },
                        { month: '11月', text: '公司党支部成立。' },
                        { month: '07月', text: '油田公司首次石油专用物资采购上市运行。' },
                        { month: '05月', text: '物资采购交易系统开发完成。' },
                    ]
                },
                {
                    name: 2011,
                    array: [
                        { month: '10月', text: '交易所与山东地方炼油协会成功召开山东地方炼化企业电子交易产品座谈会。' },
                        { month: '03月', text: '交易所与中石化西安石化分公司签订战略协议，西安石化液化气顺利实现上市交易。' },
                    ]
                },
                {
                    name: 2010,
                    array: [
                        { month: '06月', text: '交易所新系统正式启用运行。' },
                        { month: '02月', text: '交易所新系统顺利搭建完成。' },
                    ]
                },
                {
                    name: 2009,
                    array: [
                        { month: '03月', text: '原省委书记赵乐际对交易所成功启动运行作出重要批示。' },
                        { month: '03月', text: '延长石油聚丙烯产品成功上市交易。' },
                        { month: '02月', text: '延长石油首个液化气产品实现上市交易，交易所正式启动运营。' },
                    ]
                },
                {
                    name: 2008,
                    array: [
                        { month: '10月', text: '延长石油集团成为公司控股股东，股权比例75%。' },
                    ]
                },
                {
                    name: 2007,
                    array: [
                        { month: '11月', text: '公司在西安欧亚经济论坛上揭牌成立。' },
                    ]
                }

            ]
        }
    },
    destroyed () {
        map.clearMap();
    },
    mounted () {
        this.imgHeight = '400px'
        let that = this
        window.onresize = function temp () {
            // 通过点语法获取img的height属性值
            that.imgHeight = `${that.$refs.imgHeight['0'].height}px`
        }
        map.initMap();
        this.getSwiperList()
    },
    methods: {
        /**
         * 获取轮播与头条
         */
        getSwiperList () {
            this.$axios.get("/yc/rotationImg/list", {
                params: {
                    type: 2
                }
            }).then(res => {
                if (res.data.code == '200') {
                    this.imgList = []
                    res.data.data.list.forEach(e => {
                        this.imgList.push(e.rotationImg)
                    })
                    setTimeout(() => {
                        this.imgHeight = `${this.$refs.imgHeight['0'].height}px`
                    }, 500);
                }
            })
        },
        toright () {
            var outDiv = document.getElementById("out");
            var scrollWidth = outDiv.scrollWidth;
            this.scrollLeft += this.step;
            if (this.scrollLeft + 1200 >= scrollWidth) {
                this.scrollLeft = scrollWidth - 1200
            }
            outDiv.scrollLeft = this.scrollLeft;
        },
        toleft () {
            let outDiv = document.getElementById("out");
            this.scrollLeft -= this.step;
            if (this.scrollLeft < 0) {
                this.scrollLeft = 0
            }
            outDiv.scrollLeft = this.scrollLeft;
        },
    },
}
</script>
<style lang="scss" scoped>
.gsjj {
    min-width: 1200px;
    box-sizing: border-box;
    height: 700px;
    display: flex;
    align-items: center;
    background: #f3f7fc;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .flex_row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .gsjj_con {
                display: flex;
                flex-direction: column;
                // justify-content: space-around;
                margin-top: 50px;
                font-size: 17px;
                line-height: 1.5;
                div {
                    padding-bottom: 20px;
                }
            }
        }
    }
}
.qywh {
    min-width: 1200px;
    box-sizing: border-box;
    height: 700px;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
.fzlc {
    min-width: 1200px;
    box-sizing: border-box;
    height: 700px;
    display: flex;
    align-items: center;
    background: #ffffff;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .fzlc_con {
            width: 1200px;
            overflow-x: hidden;

            z-index: 1000;
            display: flex;
            flex-direction: row;
            .con {
                margin: 0 10px;
                .year {
                    font-size: 30px;
                    font-family: "Lucida Sans", "Lucida Sans Regular",
                        "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
                        sans-serif;
                    font-weight: 600;
                    color: #c50007;
                    line-height: 50px;
                }
            }
        }
        .month_con {
            width: 310px;
            height: 387px;
            background: #ffffff;
            box-shadow: 0px 2px 20px 0px #e9effa;
            border-radius: 8px;
            padding: 20px;
            font-size: 14px;
            margin-bottom: 10px;
            .flex_con {
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
            }
        }
    }
}
.qyry {
    min-width: 1200px;
    box-sizing: border-box;
    height: 700px;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        /deep/ .el-carousel__arrow {
            display: none !important;
        }
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .xiangkuang_row {
            width: 1200px;
            display: flex;
            position: absolute;
            top: -14px;
            left: 76px;
            .xiangkuang_one {
                display: flex;
                flex-direction: column;
                align-items: center;
                .xiangkuang {
                    width: 240px;
                    height: 170px;
                    margin: 10px 10px 30px 10px;
                    background: url(../assets/img/xiangkuang.png) no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 220px;
                        height: 150px;
                    }
                }
                .bt {
                    width: 200px;
                    text-align: center;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
.lxwm {
    min-width: 1200px;
    box-sizing: border-box;
    height: 700px;
    display: flex;
    align-items: center;
    background: #fff;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .flex_row {
            display: flex;
            justify-content: space-between;
            #container {
                width: 600px;
                height: 400px;
                margin-right: 20px;
            }
            .dz {
                font-size: 18px;
                font-family: "Lucida Sans", "Lucida Sans Regular",
                    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
                    sans-serif;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
}
.ball {
    width: 16px;
    height: 16px;
    border: 1px solid #c50007;
    border-radius: 100px;
    margin: 2px 10px;
}
.ball2 {
    width: 16px;
    height: 16px;
    border-radius: 100px;
    margin: 2px 10px;
    border: 4px solid #ec999c;
    background: #c50007;
    margin: 14px 0 50px 62px;
}
.line {
    position: absolute;
    top: 156px;
    width: 100%;
    left: 0;
    height: 1px;
    border: 1px solid #ff9b96;
    z-index: 50;
}
.arrow {
    width: 30px;
    height: 30px;
    background: #b1b1b1;
    color: #fff;
    cursor: pointer;
    border-radius: 2px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    &:hover {
        background: #c50007;
    }
}
</style>
